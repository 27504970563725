import orderInfo from './components/order-info/index.vue'
import orderList from './components/order-list/index.vue'
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "order",
  components:{
    orderInfo,
    orderList
  },
  data() {
    return {
      checkedDelivery:'',
      check:false,
      deliveryAddress:{
          category:[
          {
            id:0,
            title:this.$t('pickupFromStore'),
            price: this.$t('free'),
            description: this.$t('pickupFromStoreDescription'),
          },           {
            id:1,
            title:this.$t('deliveryNp'),
            price: '45 грн',
            description: this.$t('deliveryNpDescription'),
          },
          {
            id:2,
            title:this.$t('deliveryCourier'),
            price: '80 грн',
            description: this.$t('deliveryCourierDescription'),
          },
          {
            id:3,
            title:this.$t('deliveryUkrPoshta'),
            price: '80 грн',
            description: this.$t('deliveryCourierDescription'),
          },
        ]
      },
      checked: [],
      payload: {
        email: ''
      },
      validationErrors: {
        email: []
      }
    }
  },
  validations: {
    payload: {
      phone: {
        required,
        minLength: minLength(19)
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      email: {
        required,
        email,
        maxLength: maxLength(30),
      },
    }
  },
  computed: {
    disableButton(){
      if(
        this.payload.email === ''
      ){
        return true
      }else{
        return false
      }
    },
    errorsMessages() {
      let errors = [];
      for (let i  in this.validationErrors) {
        this.validationErrors[i].forEach(e => {
          errors.push(e);
        })
      }
      return errors;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 12));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({

    }),

    ...mapMutations({

    }),
    setCheck(val){
      this.check = val.check
    },
    createOrder(){

      this.$refs.order.submit()
    },
    // submit() {
    //   this.$v.$touch();
    //   if (!this.$v.$invalid) {
    //     this.send(this.payload)
    //       .then(resp => {
    //         this.changeShowPopupRestore(false);
    //         this.changeContentPopup({
    //           title: this.$t('restorePassword'),
    //           text: 'Вам на электронную почту было отправлено письмо, ' +
    //             'чтоб изменить пароль, следуйте инструкции в письме'
    //         });
    //         this.showPopup(true);
    //       })
    //       .catch(error => {home
    //         if (error.response.status === 422) {
    //           for (let field in error.response.data.errors) {
    //             this.validationErrors[field] = error.response.data.errors[field];
    //           }
    //         }
    //       });
    //
    //
    //   }
    // }
  },
}
