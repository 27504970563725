import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue'
import novaPoshtaDelivery from '../nova-poshta-delivery/index.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  props: {
    check: {
      type: Boolean,
      default: () => false
    },
    checkedDelivery: Object,
    default: () => {
    }
  },
  name: "order-info",
  components: {
    mainSelect,
    novaPoshtaDelivery,
    DatePicker
  },
  data() {
    return {
      selectedDayDelivery: '',
      timeError: false,
      dayError: false,
      dateVal: new Date(),
      dateFormat: 'yyyy-MM-dd',
      selectedHour: [],
      hour: ['9.00 – 12.00', '12.00 – 16.00', '16.00 – 18.00'],
      selectedDay: null,
      value2: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      novaPoshtaLoad: {
        city: '',
        stock: '',
      },
      UkrPoshtaLoad: {
        city: '',
        stock: '',
      },
      recipient: [
        {
          id: 0,
          title: 'Я',
        },
        {
          id: 1,
          title: 'Друга людина',
        },
      ],
      carrierAddress: {
        city: "",
        country: "",
        flat: "",
        house: "",
        id: null,
        is_main: null,
        street: "",
      },
      deliveryAddress: {
        category: [
          {
            id: 4,
            title: this.$t('pickupFromStore'),
            price: this.$t('free'),
            description: this.$t('pickupFromStoreDescription'),
          }, {
            id: 1,
            title: this.$t('deliveryNp'),
            price: this.$t('carrierRates'),
            description: this.$t('deliveryNpDescription'),
          },
          {
            id: 3,
            title: this.$t('deliveryCourier'),
            price: this.$t('carrierRates'),
            description: this.$t('deliveryCourierDescription'),
          },
          {
            id: 0,
            title: this.$t('deliveryUkrPoshta'),
            price: this.$t('carrierRates'),
            description: this.$t('deliveryUkrPoshtaDescription'),
          },
        ]
      },
      deliveryPay: [
        {
          id: 0,
          title: this.$t('paymentCash'),
        },
        {
          id: 1,
          title: this.$t('paymentCard'),
        },
        {
          id: 2,
          title: this.$t('paymentOnAccount'),
        },
      ],
      checked: [],
      checkedRecipient: [],
      payChecked: [],
      payload: {
        email: '',
        first_name: '',
        second_name: '',
        phone: '',
        viber: '',
        message: '',
      },
      anotherPayload: {
        another_first_name: '',
        another_second_name: '',
        another_phone: '',

      },
      validationErrors: {},
    }
  },
  validations() {
    if (this.checkedRecipient.id === 0) {
      return {
        payload: {
          phone: {
            required,
            minLength: minLength(19)
          },
          first_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },
          second_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },
          email: {
            required,
            email,
          },
        }
      }
    } else {
      return {
        payload: {
          phone: {
            required,
            minLength: minLength(19)
          },
          first_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },
          second_name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(20)
          },
          email: {
            required,
            email,
          },
        },
        //  ertetert
        anotherPayload: {
          another_first_name: {
            required
          },
          another_second_name: {
            required
          },
          another_phone: {
            required
          }
        }
      }
    }
    if (this.checked.id === 3) {
      return {
        carrierAddress: {
          street: {
            required
          }
        }
      }

    }


  },
  mounted() {
    this.checked = this.deliveryAddress.category[0]
    this.payChecked = this.deliveryPay[0]
    this.checkedRecipient = this.recipient[0]

  },
  computed: {
    disableButton() {
      if (
        this.payload.email === ''
      ) {
        return true
      } else {
        return false
      }
    },
    streetError() {
      let error = [];
      if (this.carrierAddress.street === '') {
        error.push(this.$t('validationRequired'))
      }
      return error;
    },
    houseError() {
      let error = []
      if (this.carrierAddress.house === '') {
        error.push(this.$t('validationRequired'))
      }
      return error
    },
    anotherFirstNameErrors() {
      let error = [];
      if (!this.$v.anotherPayload.another_first_name.$dirty) {
        return error;
      }
      if (!this.$v.anotherPayload.another_first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.another_first_name) {
        this.validationErrors.another_first_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    anotherSecondNameErrors() {
      let error = [];
      if (!this.$v.anotherPayload.another_second_name.$dirty) {
        return error;
      }
      if (!this.$v.anotherPayload.another_second_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.another_second_name) {
        this.validationErrors.another_second_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    anotherPhoneErrors() {
      let error = [];
      if (!this.$v.anotherPayload.another_phone.$dirty) {
        return error;
      }
      if (!this.$v.anotherPayload.another_phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.another_phone) {
        this.validationErrors.another_phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    errorsMessages() {
      let errors = [];
      for (let i in this.validationErrors) {
        this.validationErrors[i].forEach(e => {
          errors.push(e);
        })
      }
      return errors;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.second_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.second_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.second_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.second_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.second_name) {
        this.validationErrors.second_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 12));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    findMainAddress() {
      let obj = {}
      if (this.user) {
        for (let i in this.user.addresses.data.carrier_post) {
          if (this.user.addresses.data.carrier_post[i].is_main === 1) {
            obj = this.user.addresses.data.carrier_post[i]
          }
        }
        return obj
      }

    },
    splicesDelivery() {
      return Array.from(this.deliveryAddress.category).splice(0, 3)
    },
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      user: `profile/user`,
      basket: 'basket/basket',
      certificateResponse: 'order/certificateStatus'

    })
  },
  watch: {
    '$route'(newVal) {

      if (newVal) {
        if (this.isAuthenticated && this.user) {
          this.payload.first_name = this.user.first_name
          this.payload.second_name = this.user.last_name
          this.payload.phone = this.user.phone
          this.payload.viber = this.user.viber
          this.payload.email = this.user.email
          if (this.user.addresses.data.carrier_post.length) {
            Object.assign(this.carrierAddress, this.findMainAddress)
          }
        }
      }

    }
  },

  created() {
    // this.getUkrpochtaArea({area: 'Хмель'})
    this.select()
    if (this.user !== null) {
      this.fetchUser().then(() => {
        this.getUserData()
      })

    }
  },
  methods: {
    ...mapActions({
      createOrder: 'order/CREATE_ORDER',
      fetchUser: 'profile/FETCH_USER_DATA',
      getUkrpochtaArea: 'order/GET_UKRPOCHTA_AREA'
    }),
    ...mapMutations({
      resetBasket: 'basket/RESET_BASKET',
      changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
    }),
    splitDate() {
      let year = this.selectedDay.getFullYear()
      let month = this.selectedDay.getMonth() + 1
      let day = this.selectedDay.getDate()
      this.selectedDayDelivery = year + '/' + month + '/' + day


    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    select() {
      this.eventHub.$emit('delivery', this.checked)

    },
    getUserData() {
      if (this.isAuthenticated) {

        this.payload.first_name = this.user.first_name
        this.payload.second_name = this.user.last_name
        this.payload.phone = this.user.phone
        this.payload.viber = this.user.viber
        this.payload.email = this.user.email
        if (this.user.addresses.data.carrier_post.length) {
          Object.assign(this.carrierAddress, this.findMainAddress)
        }
      }
    },

    createObject() {
      let obj = {}
      let products = []

      if (this.isAuthenticated) {
        obj.address_id = this.findMainAddress.id
      } else {
        obj.street = this.carrierAddress.street
        obj.house = this.carrierAddress.house
        obj.flat = this.carrierAddress.flat
      }

      if (this.checkedRecipient.id === 1) {
        Object.assign(obj, this.anotherPayload)
      }

      if (this.certificateResponse) {
        obj.certificate_id = this.certificateResponse.data.id
      }

      for (let i in this.basket) {
        products.push({
          id: this.basket[i].id,
          count: this.basket[i].select_count,
          weight_id: this.basket[i].select_volume
        })
      }

      if (this.checked.id === 3) {
        obj.street = this.carrierAddress.street
        obj.city = this.carrierAddress.city
        obj.flat = this.carrierAddress.flat
        obj.day_delivery = this.selectedDayDelivery
        obj.hour_delivery = this.selectedHour

      }


      //#todo delete this fucking sheet
      obj.street = 'test'
      obj.house = 'test'
      // upper


      obj.products = products
      obj.additional_information = this.payload.message
      obj.is_call_back = this.check
      obj.is_another = this.checkedRecipient.id === 1 ? 1 : 0
      obj.first_name = this.payload.first_name
      obj.second_name = this.payload.second_name
      obj.payment_type = this.payChecked.id
      obj.phone = this.payload.phone
      obj.delivery_type = this.checked.id


      return obj
    },
    submit() {

      console.log(this.checked.id)

      this.$v.$touch();
      if (!this.$v.$invalid) {

        if (this.checked.id !== 3) {

          this.createOrder(this.createObject()).then(() => {
            this.resetBasket()
            this.$router.push({name: 'home'}).catch(() => {
            })
            this.$toasted.success(this.$t('successCreateOrder'))
          }).catch(e => {
            throw  e
          })
        }
        if (this.checked.id === 3) {

          if (!this.selectedHour.length) {
            this.timeError = true
          } else {
            this.timeError = false
          }
          if (this.selectedDay === null) {
            this.dayError = true
          } else {
            this.dayError = false
          }
          this.splitDate()

          if (!this.timeError && !this.dayError && !this.houseError.length && !this.streetError.length) {

            this.createOrder(this.createObject()).then(() => {
              this.resetBasket()
              this.$router.push({name: 'home'}).catch(() => {
              })
              this.$toasted.success(this.$t('successCreateOrder'))
            }).catch(e => {
              throw  e
            })
          }
        }

      }
    }
  },
}
