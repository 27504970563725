import autocomplete from '@/components/autocomplete/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
  name: 'delivery-nova-poshta',
  // mixins: [buyFlowMixin],
  data() {
    return {
      town: 'Киев',
      department: '',
      isValid: false,
      isValidTown: false,
      isValidDepartment: false,
    }
  },
  components: {
    autocomplete
  },
  computed: {
    ...mapGetters({
      towns: 'order/towns',
      departments: 'order/departments',
      user: `profile/user`,
      isAuthenticated: `auth/isAuthenticated`
    }),
  },
  watch: {
    town() {
      const currentTown = this.towns.find(e => e.DescriptionRu === this.town)
      this.department = ''

      if (!currentTown) {
        return
      }

      if (this.town === currentTown.DescriptionRu) {
        this.onRequestDepartments('')
      } else {
        this.setDepartments([])
      }
      this.changeAddress({town: this.town, department: this.department})

    },
    department() {
      this.checkValidData()
    }
  },
  created() {
    if (this.isAuthenticated) {
      let mainAddress
      if (this.user.addresses.data.new_post) {
        mainAddress = this.user.addresses.data.new_post.find(e => e.is_main === 1)
      }

      if (mainAddress) {
        this.town = mainAddress.city
      }

      this.onRequestTown(this.town)
      setTimeout(() => {
        this.onRequestDepartments(this.department)
      }, 600)
    } else {
      this.onRequestTown(this.town)
      this.onRequestDepartments('')
    }
  },
  methods: {
    ...mapActions({
      fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
      fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',
      fetchUser: 'profile/FETCH_USER_DATA'
    }),
    ...mapMutations({
      setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
      changeAddress: 'order/CHANGE_ADDRESS',
    }),

    onRequestTown(val) {
      const formData = JSON.stringify({
        "modelName": "Address",
        "calledMethod": "getCities",
        "methodProperties": {
          "FindByString": val
        },
        "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
      })
      this.fetchTowns(formData).then(() => {
        const currentTown = this.towns.find(e => e.DescriptionRu === this.town)
        // this.department = ''
        if (!currentTown) {
          return
        }
        if (this.town === currentTown.DescriptionRu) {
          this.onRequestDepartments(this.department)
        } else {
          this.setDepartments([])
        }
        this.checkValidData()
      })
    },
    onRequestDepartments(val) {
      const formData = JSON.stringify({
        "modelName": "AddressGeneral",
        "calledMethod": "getWarehouses",
        "methodProperties": {
          "CityName": this.town,
          "FindByString": val,
          "Language": "ru"
        },
        "apiKey": process.env.VUE_APP_NOVAPOSHTA_API_KEY
      })
      this.fetchDepartments(formData).then(() => {
        if (this.user) {
          let mainAddress = this.user.addresses.data.new_post.find(e => e.is_main === 1)
          this.department = mainAddress.department
        }

      })
    },
    checkValidData() {
      const currentTown = this.towns.find(e => e.DescriptionRu === this.town)
      if (currentTown) {
        this.isValidTown = this.town === currentTown.DescriptionRu && this.town.length !== 0
      } else {
        this.isValidTown = false
      }

      const currentDepartment = this.departments.find(e => e.DescriptionRu === this.department)
      if (currentDepartment) {
        this.isValidDepartment = this.department === currentDepartment.DescriptionRu && this.department.length !== 0
      } else {
        this.isValidDepartment = false
      }

      this.isValid = this.isValidTown && this.isValidDepartment
      this.changeAddress({town: this.town, department: this.department})
      this.$emit('input', this.isValid)
    }
  }
}
