import {mapGetters} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';
import basket from "../../../../../../store/modules/basket";

export default {
  name: "order-list",
  data() {
    return {
      openItem: false,
      newPrice: 0,
      newPriceSum: 0,
      check: false,
      showDiscountList: false,
      beauticianDiscount: 0,
      selectDelivery: {
        price: 'безкоштовно'
      }

    }
  },
  watch: {},
  methods: {
    isShowDiscountList() {
      switch (true) {
        case(this.isAuthenticated && this.certificateResponse && this.certificateResponse.data !== undefined): {
          return this.showDiscountList = true;
        }
        case(!this.isAuthenticated && this.certificateResponse && this.certificateResponse.data === undefined): {
          return this.showDiscountList = false;
        }
        case(this.isAuthenticated && this.certificateResponse && this.certificateResponse.data === undefined): {
          return this.showDiscountList = false;
        }

      }
    },


    getCurrentPrice(item) {
      if (item.weights) {
        for (let i = 0; i < item.weights.length; i++) {
          let attr = item.weights[i]
          if (attr.sale.new_price && item.select_volume.weight === attr.weight) {
            return attr.sale.new_price
          }
          if (!attr.sale.new_price) {
            console.log(attr.price, 'price');
            return attr.price
          }
        }
      }
    },
    handle() {
      this.$emit('handle', {check: !this.check})
    },
    select(val) {
      this.check = val
    },
    submit() {
      this.$emit('change-submit')
    }
  },
  components: {
    VueSlideToggle
  },

  created() {
    this.isShowDiscountList()
    console.log(this.basket);
  },
  computed: {
    getBeauticianDiscount() {
      return this.beauticianDiscount = (this.totalAmount - this.newPriceSum) / 100 * 10
    },
    amountWithOutDiscount() {

      if (this.isAuthenticated) {
        let priceWithDiscount = (this.totalAmount - this.newPriceSum) - this.discount.your_discount + this.newPriceSum
        console.log(priceWithDiscount);
        if (this.certificateResponse) {
          return priceWithDiscount - this.getBeauticianDiscount - this.certificateResponse.data.amount - this.usedBonuses
        }
        if (this.usedBonuses) {
          return priceWithDiscount - this.getBeauticianDiscount - this.usedBonuses
        }
        if (this.user.professional_promo) {
          return priceWithDiscount - this.getBeauticianDiscount
        }
        if (this.discount.your_discount && this.newPrice) {
          return priceWithDiscount
        } else {
          return this.totalAmount - this.discount.your_discount
        }
      } else {
        return this.totalAmount
      }


    },
    totalAmount() {
      return this.basket.reduce((accumulator, currentValue) => {

        let currentPrice

        if (currentValue.weights) {
          for (let i = 0; i < currentValue.weights.length; i++) {
            let attr = currentValue.weights[i]
            if (attr.sale.new_price !== null && currentValue.select_volume.weight === attr.weight) {

              this.newPrice = attr.sale.new_price
              this.newPriceSum = currentValue.select_count * attr.sale.new_price
            }
            if (attr.sale.new_price === null) {
              currentPrice = currentValue.select_price
            }
          }
        }

        if (currentValue.weights[0].sale.new_price !== null && currentValue.weights[0].sale.new_price !== 0) {
          return this.totalPrice = accumulator + (currentValue.weights[0].sale.new_price * currentValue.select_count)
        } else {
          return this.totalPrice = accumulator + (currentValue.select_price * currentValue.select_count)
        }


      }, 0);


    },
    totalDiscount() {
      let totalSum = 0
      if (this.certificateResponse) {
        totalSum = totalSum + parseInt(this.certificateResponse.data.amount)
      }
      if (this.usedBonuses) {
        totalSum = totalSum + this.usedBonuses
      }
      if (this.discount) {
        totalSum = totalSum + this.discount.your_discount
      }
      if (this.beauticianDiscount) {
        totalSum = totalSum + this.beauticianDiscount
      }
      return totalSum
    },
    // amountWithDiscount() {
    //     if (this.certificateResponse) {
    //         return this.totalAmount - this.certificateResponse.data.amount
    //     } else {
    //         return this.totalAmount
    //     }
    //
    // },

    ...mapGetters({
      basket: 'basket/basket',
      certificateResponse: 'order/certificateStatus',
      isAuthenticated: `auth/isAuthenticated`,
      usedBonuses: 'basket/setBonuses',
      discount: 'basket/discount',
      bonuses: 'basket/bonuses',
      percentDiscount: 'basket/percentDiscount',
      user: 'profile/user',
    }),

  }

}
